import React from "react"
import { FC } from "react"
import styled from "styled-components"
import { Lang, LANGS } from "../../constants"

const Container = styled.div`
  display: flex;
`
const AppleA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const AppleImage = styled.img`
  margin-bottom: 6px;
`
const GoogleBadgeImage = styled.img`
  height: 74px;
`

interface Props {
  width: number
  height: number
  lang: Lang
}

const StoreBadge: FC<Props> = ({ width, height, lang }) => {
  const countryCode = lang === LANGS.ja ? "jp" : "us"
  const langCode = lang === LANGS.ja ? "ja" : "en"
  return (
    <Container>
      <AppleA
        href={`https://itunes.apple.com/${countryCode}/app/id1473728572`}
        target="_blank"
        rel="noopener"
      >
        <AppleImage
          src={`/app-store-${langCode}-${countryCode}.svg`}
          alt="Apple Store"
          width={width}
          height={height}
        />
      </AppleA>
      <a
        href="https://play.google.com/store/apps/details?id=com.fireworksFestivals"
        target="_blank"
      >
        <GoogleBadgeImage
          alt="Google Play で手に入れよう"
          src={`https://play.google.com/intl/en_us/badges/static/images/badges/${langCode}_badge_web_generic.png`}
          style={{
            height: lang === LANGS.ja ? 66 : 74,
            marginLeft: lang === LANGS.ja ? 8 : 0,
          }}
        />
      </a>
    </Container>
  )
}

export default StoreBadge
