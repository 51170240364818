import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FirstScreen from "../components/first_screen"
// import Blogs from "../components/blogs"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  // const posts = data.allMarkdownRemark.nodes

  const title = "Fireworks Maps Japan"
  const description =
    "A map app of fireworks festivals in Japan. Find events near you on the map. Stock events in a favorite list."

  return (
    <Layout location={location} title={siteTitle} isAbsolute isLangShown>
      <FirstScreen
        title={title}
        description={description}
        pathname={location.pathname}
      />
      {/*
      <Blogs title="Stories" posts={posts} />
      */}
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ location: { pathname } }) => (
  <Seo title="Fireworks Map App" pathname={pathname} />
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^(?=.*-en/).*$/" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
