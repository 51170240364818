import React, { FC } from "react"
import styled from "styled-components"
import { LANGS } from "../../constants"
import StoreBadge from "./store-badge"

const FullScreenContainer = styled.div`
  background: url(/background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: 100vh;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Description = styled.div`
  margin: 0 20px 20px;
  line-height: 1.5;
  text-align: center;
`

const PhonesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`
const LeftPhone = styled.img`
  position: relative;
  z-index: 0;

  width: 114.22px;
  height: 226.67px;
  left: 20px;
  @media (min-width: 768px) {
    width: 171.33px;
    height: 340px;
    left: 30px;
  }
`
const RightPhone = styled.img`
  position: relative;
  z-index: 1;

  width: 127.46px;
  height: 253.33px;
  right: 20px;
  @media (min-width: 768px) {
    width: 191.19px;
    height: 380px;
    right: 30px;
  }
`

interface Props {
  title: string
  description: string
  pathname: string
}

const FirstScreen: FC<Props> = ({ title, description, pathname }) => {
  const lang = pathname.includes("/ja") ? LANGS.ja : LANGS.en
  return (
    <FullScreenContainer>
      <h2>{title}</h2>
      <Description>{description}</Description>
      <PhonesContainer>
        <LeftPhone src="/Nexus5X.png" alt="Android" />
        <RightPhone src="/iPhoneXsMax.png" alt="iPhone" />
      </PhonesContainer>
      <StoreBadge width={168.75} height={50} lang={lang} />
    </FullScreenContainer>
  )
}

export default FirstScreen
